import React from "react";
import styled from "styled-components"
import { graphql } from "gatsby";
import { ButtonLink } from "../components/ButtonLink";
import Layout from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import ArticleCard from "../components/ArticleCard";
import {breakpoints} from "../style/theme";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem 0rem;
  margin: 2rem 0;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.sm}px) {
    display: block;
    padding: 0.5rem 0;
  }
`

export default function CategoryTemplate({
  data: {
    site: {
      siteMetadata: {
        siteUrl,
        templates: {
          posts: {
            pathPrefix,
            filters: {
              category: { pathPrefixCategory, totalPosts },
            },
          },
        },
      },
    },
    allMdx: { edges: posts },
  },
  pageContext: { category }
}) {
  return (
    <Layout title={`${category}の記事一覧`} shownAdSense={false}>
      <Breadcrumbs
        links={[
          {
            url: `/${pathPrefix}`,
            title: 'BLOG',
          },
          {
            url: null,
            title: category,
          },
        ]}
      />
      {posts.map(
        ({
          node: {
            excerpt,
            //fileAbsolutePath,
            frontmatter: { id, title, category, description, tags, createdDate, updatedDate, featuredImage },
          },
        }) => {
          //const postDate = path
          //  .basename(fileAbsolutePath)
          //  .split("-")
          //  .splice(0, 3)
          //  .join("-");
          return (
            <ArticleCard
              id={id}
              featuredImage={featuredImage}
              title={title}
              category={category}
              description={description}
              tags={tags}
              url={`/${pathPrefix}/${id}`}
              createdDate={createdDate}
              updatedDate={updatedDate}
              excerpt={excerpt}
            />
          );
        }
      )}
      {posts.length === totalPosts && (
        <ButtonWrapper>
          <ButtonLink
            to={`/${pathPrefixCategory}/${category}/page/1`}
          >
            {category} の記事を全て見る
          </ButtonLink>
        </ButtonWrapper>
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($category: String!, $limit: Int!) {
    site {
      siteMetadata {
        siteUrl
        templates {
          posts {
            pathPrefix
            filters {
              category {
                pathPrefixCategory
                totalPosts
              }
            }
          }
        }
      }
    }
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/" }
        frontmatter: { category: { in: [$category] } }
      }
      sort: { order: DESC, fields: [fileAbsolutePath] }
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 50, truncate: true)
          fileAbsolutePath
          frontmatter {
            id
            title
            category
            description
            tags
            createdDate
            updatedDate
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
